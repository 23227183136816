import { HStack, Icon, Input, InputGroup, InputRightElement } from "@chakra-ui/react"
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AlertButton from "../buttons/AlertButton"
import MSGButton from "../buttons/MSGButton"
import ThemeChanger from "../buttons/ThemeChanger"
import UserButton from "../buttons/UserButton"
import Shell from "../Shell"

const NavigationBar = () => {
    return (
        <Shell as={HStack} rounded='none' justifyContent={['end', 'end', 'space-between']} backgroundColor='bgSecondary' zIndex={9} pos='sticky' top={0}>
            <InputGroup w='fit-content' display={['none', 'none', 'block']}>
                <InputRightElement color='lightGray'>
                    <Icon as={FontAwesomeIcon} icon={faMagnifyingGlass} />
                </InputRightElement>
                <Input variant='filled' placeholder="Search the dashboard" />
            </InputGroup>

            <HStack>
                <MSGButton />
                <AlertButton />
                <ThemeChanger />
                <UserButton />
            </HStack>
        </Shell>
    )
}

export default NavigationBar