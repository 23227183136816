import { Box, BoxProps, ChakraComponent } from "@chakra-ui/react";

type ShellComponent = ChakraComponent<'div', {}>

const Shell = ((props: BoxProps) => {
    return (
        <Box p={3} gap={3} rounded='lg' {...props} />
    )
}) as ShellComponent;

export default Shell;