import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Button, Flex, Icon, Menu, MenuButton, MenuGroup, MenuItem, MenuList, Portal, StackDivider, VStack } from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCog, faHouse, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import Logo from "../Logo";
import Shell from "../Shell";
import { useMD } from "../../context/MDContext";


type NavLinkProps = {
    children?: ReactNode
}
const NavLink = ({ children }: NavLinkProps) => {
    const variant = useMD();
    return variant ? (
        <MenuItem>{children}</MenuItem>
    ) : (
        <Button w='full' variant='ghost' justifyContent='start'>{children}</Button>
    )
}

type NavLinkCategoryProps = {
    children?: ReactNode,
    title: string,
    icon: IconProp
};
const NavLinkCategory = ({ children, title, icon }: NavLinkCategoryProps) => {
    const variant = useMD();

    return variant ? (
        <Menu placement="right-start" autoSelect={false}>
            <MenuButton as={Button} w='full' variant='ghost' color='darkGray'>
                <Flex justifyContent={'center'} alignItems={'center'} gap={3}>
                    <Icon as={FontAwesomeIcon} icon={icon} size='lg' fixedWidth />
                </Flex>
            </MenuButton>

            <Portal>
                <MenuList>
                    <MenuGroup title={title}>
                        {children}
                    </MenuGroup>
                </MenuList>
            </Portal>
        </Menu>
    ) : (
        <AccordionItem border={'none'} w='full'>
            <Button as={AccordionButton} alignItems='center' gap={3}>
                <Icon color='darkGray' as={FontAwesomeIcon} icon={icon} size='lg' fixedWidth />
                {title}
                <AccordionIcon ml='auto' color='lightGray' />
            </Button>
            <AccordionPanel w='full'>
                <VStack w='full' divider={<StackDivider />}>
                    {children}
                </VStack>
            </AccordionPanel>
        </AccordionItem>
    )
}



const links = (
    <>
        <NavLinkCategory title="Dashboard" icon={faHouse}>
            <NavLink>Dashboard</NavLink>
            <NavLink>Analytics</NavLink>
            <NavLink>Active clients</NavLink>
            <NavLink>Review</NavLink>
        </NavLinkCategory>

        <NavLinkCategory title="Users" icon={faUsers}>
            <NavLink>Manage users</NavLink>
            <NavLink>Create user</NavLink>
            <NavLink>Remove user</NavLink>
        </NavLinkCategory>

        <NavLinkCategory title="Settings" icon={faCog}>
            <NavLink>Manage alerts</NavLink>
            <NavLink>Change theme</NavLink>
            <NavLink>Change password</NavLink>
            <NavLink>Advanced settings</NavLink>
        </NavLinkCategory>
    </>
)
const NavLinkGroup = () => {
    const variant = useMD();

    return variant ? (
        <VStack w='full'>
            {links}
        </VStack>
    ) : (
        <VStack w='full' as={Accordion} allowToggle>
            {links}
        </VStack>
    )
}

const NavigationSidebar = () => {
    return (
        <Shell
            as='nav'
            pos='sticky'
            top={0}
            overflowY='auto'
            h='100vh'
            w='max-content'
            className="narrowScroll"
            shadow='xl'
            rounded='none'
            pt={0}
            backgroundColor='bgPrimary'
        >
            <Shell as={VStack} pt={0}>
                <Logo />
                <NavLinkGroup />
            </Shell>
        </Shell>
    )
}

export default NavigationSidebar;