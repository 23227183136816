import { useBreakpointValue } from "@chakra-ui/react";
import { createContext, ReactNode, useContext } from "react"

type MDContextProps = {
    variant: boolean;
}
const MDContext = createContext<MDContextProps>({ variant: false });

const useMDBoolean = () => {
    const variant = useBreakpointValue({
        base: true,
        md: false,
    }, {
        fallback: 'base'
    })

    return variant ?? true;
}

const MDProvider = ({ children }: { children: ReactNode }) => {
    const value = { variant: useMDBoolean() };

    return (
        <MDContext.Provider value={value}>
            {children}
        </MDContext.Provider>
    )
}

const useMD = () => {
    const context = useContext(MDContext);
    if (context === undefined) {
        throw new Error('useMD must be used within a MDProvider');
    }

    return context.variant;
}

export { MDProvider, useMD }