import { Flex } from "@chakra-ui/react";
import { ReactNode } from "react";
import { MDProvider } from "../context/MDContext";
import NavigationBar from "./navigation/NavigationBar";
import NavigationSidebar from "./navigation/NavigationSidebar";
import Shell from "./Shell";

type LayoutProps = {
    children?: ReactNode;
}
const Layout = ({ children }: LayoutProps) => {
    return (
        <MDProvider>
            <Flex w='full'>
                <NavigationSidebar />

                <Flex direction='column' flex='1'>
                    <NavigationBar />
                    <Shell as={Flex} flexDir='column' p={[3, 5, 5]} gap={5} maxW='container.xl' mx='auto'>
                        {children}
                    </Shell>
                </Flex>
            </Flex>
        </MDProvider>
    )
}

export default Layout