import { Avatar, Center, Heading, HStack, Text, ThemingProps, useColorModeValue, VStack } from "@chakra-ui/react";
import { customTheme } from "..";
import hexToRgb from "../utils/hexToRgb";
import transparentize from "../utils/transparentize";
import Shell from "./Shell";

export type MyMSGProps = {
    sender: string,
    msg: string,
    date: string,
}

const MyMSG = ({ sender, msg, date }: MyMSGProps) => {
    const colorScheme: ThemingProps['colorScheme'] = 'blue';

    const lightColor = transparentize(hexToRgb(customTheme.colors[colorScheme]['300'])!, .6);
    const darkColor = transparentize(hexToRgb(customTheme.colors[colorScheme]['300'])!, .18);

    const bgColor = useColorModeValue(lightColor, darkColor);
    const avatarBgColor = useColorModeValue(customTheme.colors[colorScheme]['300'], customTheme.colors[colorScheme]['600']);

    const color = useColorModeValue('gray.800', 'white')
    const dateColor = useColorModeValue('gray.700', 'gray.300');

    const [firstName] = sender.split(' ');

    return (
        <Shell w='full' as={HStack} bgColor={bgColor} color={color} p={0} overflow='hidden' alignItems='stretch'>
            <Center bgColor={avatarBgColor} p={2}>
                <Avatar name={sender} shadow='dark-lg' />
            </Center>
            <Shell as={VStack} pl={0} w='full' alignItems='start'>
                <Heading as='h3' size='lg'>{firstName}</Heading>
                <Text>{msg}</Text>
                <Text w='full' textAlign='right' fontSize='xs' color={dateColor}>{date}</Text>
            </Shell>
        </Shell>
    )
}

export default MyMSG;