import { Avatar, AvatarBadge, Box, Button, Center, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { faArrowRightFromBracket, faCircleDot, faCog, faEnvelope, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMD } from "../../context/MDContext";

const UserButton = () => {
    const variant = useMD();
    const text = "Hello, Ben";

    return (
        <Menu>
            <Box>
                <MenuButton as={Button} cursor='pointer' variant={variant ? 'unstyled' : 'solid'} colorScheme={'blue'}  mr={variant ? 0 : 10} position='relative'>
                    <Center>
                        <Text display={variant ? 'none' : 'block'}>{text}</Text>
                        <Avatar size='md' pos={variant ? 'static' : 'absolute'} right={variant ? 0 : -10} borderWidth={5} borderColor={'bgSecondary'}>
                            <AvatarBadge boxSize='1.1em' bg='green.500' borderColor='bgSecondary' />
                        </Avatar>
                    </Center>
                </MenuButton>
            </Box>
            <MenuList>
                <MenuItem icon={<FontAwesomeIcon icon={faUser} />}>Profile</MenuItem>
                <MenuItem icon={<FontAwesomeIcon icon={faEnvelope} />}>Inbox</MenuItem>
                <MenuItem icon={<FontAwesomeIcon icon={faCog} />}>Settings</MenuItem>
                <MenuItem icon={<FontAwesomeIcon icon={faCircleDot} />}>Change status</MenuItem>
                <MenuItem icon={<FontAwesomeIcon icon={faArrowRightFromBracket} />}>Logout</MenuItem>
            </MenuList>
        </Menu>
    )
}

export default UserButton;