import { Heading, HStack, Image } from "@chakra-ui/react"

const Logo = () => {
    return (
        <HStack pos='sticky' top={0} zIndex='9' bgColor={'bgPrimary'} py={5}>
            <Image src="logo.svg" boxSize={['50px', '50px', '64px']} />
            <Heading as='h1' size='lg' display={['none', 'none', 'none', 'block']}>Dashboard</Heading>
        </HStack>
    )
}

export default Logo;