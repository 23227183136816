import { Box, Button, Center, Circle, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, useDisclosure, VStack } from "@chakra-ui/react";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyAlert, { MyAlertProps } from "../MyAlert";
import Shell from "../Shell";

const AlertCircle = () => {
    return (
        <Box pos='absolute' top={1} right={1}>
            <Circle size='10px' bg='blue.400' pos='absolute' className='ping' />
            <Circle size='10px' bg='blue.500' pos='relative' />
        </Box>
    )
}

const alerts: MyAlertProps[] = [
    {
        status: 'info',
        title: 'New update is available',
        description: 'Dashboard version 2.0 is out. Consider updating.',
        date: '1:45 PM, Today'
    },
    {
        status: 'error',
        title: 'Permission denied',
        description: 'User "Sam" tried to cancel an order.',
        date: '7:02 AM, Today'
    },
    {
        status: 'success',
        title: 'Created new order',
        description: 'New order for: "Wood planks" has been created.',
        date: '6:38 AM, Today'
    },
    {
        status: 'warning',
        title: 'Low disk space',
        description: 'The server is running out of space. 5 GB left from 2 TB. Consider upgrading.',
        date: '4:00 AM, Today'
    }
]

const AlertButton = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Button aria-label="Alert" variant='icon' pos='relative' p={0} onClick={onOpen}>
                <FontAwesomeIcon icon={faBell} fixedWidth />
                <AlertCircle />
            </Button>

            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                size='md'
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Recent alerts</DrawerHeader>

                    <DrawerBody>
                        <Shell as={VStack}>
                            {alerts.map((a, id) => {
                                return (
                                    <MyAlert key={id} {...a} />
                                )
                            })}
                        </Shell>
                        <Center>
                            <Button>Load older</Button>
                        </Center>
                    </DrawerBody>
                </DrawerContent>

            </Drawer>
        </>
    )
}

export default AlertButton;