import { Box, Button, Flex, Heading, Menu, MenuButton, MenuItem, MenuList, SimpleGrid, useColorModeValue, VStack } from "@chakra-ui/react";
import { CategoryScale, Chart, ChartData, ChartOptions, Legend, LinearScale, LineElement, PointElement, Tooltip } from "chart.js";
import { Line } from "react-chartjs-2";
import { customTheme } from "../..";
import { useMD } from "../../context/MDContext";
import SummaryCard from "../cards/SummaryCard";
import Shell from "../Shell";

const labels = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
]

const OrdersPanel = () => {
    const textColor = useColorModeValue(customTheme.colors.gray[700], customTheme.colors.gray[200])
    const variant = useMD();

    const options: ChartOptions<'line'> = {
        responsive: true,
        aspectRatio: variant ? 1.1 : 2,
        maintainAspectRatio: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        elements: {
            line: {
                tension: .36,
            },
        },
        plugins: {
            legend: {
                labels: {
                    color: textColor
                }
            },

        },
        scales: {
            y: {
                ticks: {
                    color: textColor
                }
            },
            x: {
                ticks: {
                    color: textColor
                }
            },
        }
    }

    const data: ChartData<'line'> = {
        labels,
        datasets: [
            {
                data: [2, 8, 4, 12, 6, 2, 5],
                label: 'New orders',
                borderColor: customTheme.colors.blue['500'],
                backgroundColor: customTheme.colors.blue['400'],
            },
            {
                data: [1, 3, 2, 0, 0, 4, 2],
                label: 'Completed orders',
                borderColor: customTheme.colors.green['500'],
                backgroundColor: customTheme.colors.green['400'],
            }
        ]
    }

    return (
        <Shell as={VStack} bgColor='card' shadow='sm'>
            <Flex as='header' w='full' wrap='wrap' gap={4} justifyContent='space-between' alignItems='center'>
                <Heading as='h2' size='lg' flexGrow={9999}>Orders this week</Heading>
                <Menu>
                    <MenuButton as={Button} flexGrow={1}>
                        Week
                    </MenuButton>
                    <MenuList>
                        <MenuItem>Day</MenuItem>
                        <MenuItem>Week</MenuItem>
                        <MenuItem>Month</MenuItem>
                    </MenuList>
                </Menu>
            </Flex>

            <Shell as={SimpleGrid} columns={[1, 1, 1, 1, 2]} w='full'>
                <Shell p={0}>
                    <Box as={Line} data={data} options={options} />
                </Shell>

                <Shell as={SimpleGrid} columns={[1, 2]} p={0} my='auto'>
                    <SummaryCard title="27" description="Awaiting" />
                    <SummaryCard title="12" description="Completed" />
                    <SummaryCard title="5" description="Canceled" />
                    <SummaryCard title="49" description="This month" />
                </Shell>
            </Shell>
        </Shell >
    )
}

export default OrdersPanel;
