
import { Box, Circle, Heading, HStack, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Shell from "../Shell";

export type SummaryCardProps = {
    title: string,
    description: string,
    icon?: IconProp
}
const SummaryCard = ({ title, description, icon }: SummaryCardProps) => {
    const circleColor = useColorModeValue('blue.200', 'blue.800');
    const iconColor = useColorModeValue('blue.800', 'blue.200');

    return (
        <Shell as={HStack} bgColor='card' shadow='sm' borderWidth={1} >
            {icon &&
                (
                    <Circle bgColor={circleColor} size='60px'>
                        <Icon as={FontAwesomeIcon} icon={icon} size='2x' color={iconColor} />
                    </Circle>
                )
            }

            <Box>
                <Heading as={Text} mb='2'>{title}</Heading>
                <Text color='darkGray' textTransform='uppercase' fontSize='smaller'>{description}</Text>
            </Box>
        </Shell>
    )
}

export default SummaryCard;