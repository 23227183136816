import { Box, Center, Grid, Heading, Progress, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { ChartData, ChartOptions } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { customTheme } from "../..";
import TaskCard, { TaskCardProps, TaskColors, TaskTypes } from "../cards/TaskCard";
import Shell from "../Shell";

const tasks: TaskCardProps[] = [
    {
        title: 'Attend meeting',
        type: 'finished',
        hashtags: ['meeting', 'office']
    },
    {
        title: 'Prepare order #892',
        type: 'new',
        hashtags: ['order', 'garage']
    },
    {
        title: 'Fix some bugs in the dashboard',
        type: 'in progress',
        hashtags: ['bug', 'confirmed']
    },
    {
        title: 'Buy food',
        type: 'cancelled',
        hashtags: ['food', 'healthy']
    }
]

const labels = [...TaskTypes];

type ProgressBarProps = {
    label: typeof TaskTypes[number],
    value: number,
    sum: number
}
const ProgressBar = ({ label, value, sum }: ProgressBarProps) => {
    const percent = Math.floor(value / sum * 100);
    const colorScheme = TaskColors[label]

    return (
        <>
            <Text my='auto'>{label.charAt(0).toUpperCase() + label.slice(1)}</Text>
            <Text>({percent}%)</Text>
            <Progress my='auto' borderRadius='full' value={percent} colorScheme={colorScheme} />
        </>
    )
}

const TasksPanel = () => {
    const options: ChartOptions<'doughnut'> = {
        responsive: true,

        plugins: {
            legend: {
                display: false
            }
        },
        elements: {
            arc: {
                hoverOffset: 10
            }
        },
        layout: {
            padding: 10
        }
    }
    const data: ChartData<'doughnut'> = {
        labels,
        datasets: [
            {
                data: [6, 3, 13, 2],
                label: '# of tasks',
                borderColor: labels.map(label => customTheme.colors[TaskColors[label]!][500]),
                backgroundColor: labels.map(label => customTheme.colors[TaskColors[label]!][400]),
            },
        ],
    }

    const sum = data.datasets[0].data.reduce((p, c) => p + c);

    return (
        <Shell shadow='sm' bgColor='card' mb='auto'>
            <Heading as='h2' size='lg' w='full'>Tasks</Heading>
            <Shell as={Grid}
                gridTemplateAreas={[
                    `"T"
                    "D"
                    "M"`,

                    `"T"
                    "D"
                    "M"`,

                    `"T"
                    "D"
                    "M"`,

                    `"T T"
                    "D M"`,

                    `"T T"
                    "D M"`,
                ]}
                gridTemplateColumns={['auto', 'auto', 'auto', 'auto 1fr']}
            >
                <Shell as={VStack} p={0} w='full' gridArea='T'>
                    {tasks.map((task, i) => (<TaskCard {...task} key={i} />))}
                </Shell>

                <Shell as={Center} p={0} gridArea='D' minW={0} w='full' maxW={128} mx='auto'>
                    <Box as={Doughnut} data={data} options={options} />
                </Shell>

                <Shell as={SimpleGrid} my='auto' columns={3} gridTemplateColumns='auto auto 1fr'>
                    {data.datasets[0].data.map((data, i) => (
                        <ProgressBar key={i} label={labels[i]} value={data} sum={sum} />
                    ))}
                </Shell>
            </Shell>
        </Shell>
    )
}

export default TasksPanel;