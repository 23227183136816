import { Alert, AlertDescription, AlertIcon, AlertProps, AlertTitle, Box, Text } from "@chakra-ui/react";

export type MyAlertProps = {
    title: string,
    description: string,
    date: string,
    status: AlertProps['status'],
}

const MyAlert = ({ status, title, description, date }: MyAlertProps) => {
    return (
        <Alert rounded={'lg'} variant='left-accent' status={status}>
            <AlertIcon />
            <Box w='full'>
                <AlertTitle>{title}</AlertTitle>
                <AlertDescription w='full'>
                    <Text>{description}</Text>
                    <Text textAlign='right' fontSize='xs' color='darkGray'>{date}</Text>
                </AlertDescription>
            </Box>
        </Alert>
    )
}

export default MyAlert;