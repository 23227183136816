import { Button, Heading, HStack, IconButton, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, SimpleGrid, Text, ThemingProps, VStack } from "@chakra-ui/react";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Shell from "../Shell";

const weekDays = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday'
]

const monthName = 'August';
const year = 2022;
const daysAmount = 31;

type eventType = {
    title: string,
    colorScheme: ThemingProps['colorScheme']
}

type DayButtonProps = {
    day: number,
    event?: eventType
}
const DayButton = ({ day, event }: DayButtonProps) => {
    return event ? (
        <Popover>
            <PopoverTrigger>
                <Button colorScheme={event.colorScheme} key={day} p={0}>{day}</Button>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>{event.title}</PopoverHeader>
                <PopoverBody>
                    <VStack>
                        <Button w='full' colorScheme={event.colorScheme}>Confirm participation</Button>
                        <Button w='full'>Change title</Button>
                    </VStack>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    ) : (
        <Button variant='ghost' key={day} p={0}>{day}</Button>
    )
}

type eventsObj = {
    [_: number]: eventType;
}
const events: eventsObj = {
    3: {
        title: 'Meeting at the office',
        colorScheme: 'blue'
    },
    13: {
        title: 'Birthday party',
        colorScheme: 'purple'
    },
    16: {
        title: 'Something important',
        colorScheme: 'yellow'
    }
}

const CalendarPanel = () => {
    return (
        <Shell shadow='sm' bgColor='card' mb='auto'>
            <Heading as='h2' size='lg' w='full'>Calendar</Heading>
            <Shell as={VStack} w='fit-content' mx='auto'>
                <HStack w='full'>
                    <IconButton aria-label="Previous month" icon={<FontAwesomeIcon icon={faChevronLeft} />} />
                    <Button flexGrow={1}>{monthName} {year}</Button>
                    <IconButton aria-label="Next month" icon={<FontAwesomeIcon icon={faChevronRight} />} />
                </HStack>
                <Shell as={SimpleGrid} columns={7} p={0}>
                    {weekDays.map(day => (
                        <Text key={day} mx='auto' color='darkGray'>{day.slice(0, 2)}</Text>
                    ))}
                    {Array.from({ length: daysAmount }).map((_, i) => (
                        <DayButton key={i} day={i + 1} event={events[i + 1]} />
                    ))}
                </Shell>
            </Shell>
        </Shell>
    )
}

export default CalendarPanel;