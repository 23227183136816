import { SimpleGrid } from "@chakra-ui/react";
import { faDollarSign, faFileContract, faListCheck, faUserGroup } from "@fortawesome/free-solid-svg-icons";
import SummaryCard, { SummaryCardProps } from "../cards/SummaryCard";
import Shell from "../Shell";

const cards: SummaryCardProps[] = [
    {
        title: '49',
        description: 'Orders this month',
        icon: faFileContract
    },
    {
        title: '16',
        description: 'New clients this month',
        icon: faUserGroup
    },
    {
        title: '$25k',
        description: 'Past month revenue',
        icon: faDollarSign
    },
    {
        title: '4',
        description: 'New tasks this month',
        icon: faListCheck
    }
];

const SummarySection = () => {
    return (
        <Shell as={SimpleGrid} columns={[1, 1, 2, 3, 4]} p={0}>
            {cards.map((card, i) => {
                return (
                    <SummaryCard {...card} key={i} />
                )
            })}
        </Shell>
    )
}

export default SummarySection;