import { Box, Heading } from "@chakra-ui/react"

const HeaderSection = () => {
    return (
        <Box as='header'>
            <Heading as='h1'>Hello, Ben</Heading>
            <Heading as='h2' size='md' color='darkGray'>Welcome to Dashboard!</Heading>
        </Box>
    )
}

export default HeaderSection;