import { ChakraProvider, ColorModeScript, extendTheme, ThemeOverride } from '@chakra-ui/react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@fontsource/montserrat'
import { ArcElement, CategoryScale, Chart, Legend, LinearScale, LineElement, PointElement, Tooltip } from 'chart.js';

Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Tooltip,
    Legend
)
Chart.defaults.font.family = 'Montserrat'

export const customTheme = extendTheme({
    fonts: {
        body: `'sans-seris', 'montserrat'`,
    },
    colors: {
        gray: {
            750: '#202835'
        }
    },
    semanticTokens: {
        colors: {
            lightGray: {
                default: 'gray.400',
            },
            darkGray: {
                default: 'gray.500',
                _dark: 'gray.400'
            },
            bgPrimary: {
                default: 'white',
                _dark: 'gray.900'
            },
            bgSecondary: {
                default: 'gray.50',
                _dark: 'gray.750'
            },
            card: {
                default: 'bgPrimary',
                _dark: 'bgSecondary',
            },
            "chakra-body-bg": {
                _light: 'gray.100',
                _dark: 'gray.800'
            },
            "chakra-body-text": {
                _light: 'gray.700',
                _dark: 'gray.200'
            }
        }
    },
    components: {
        Button: {
            variants: {
                icon: (props) => {
                    return {
                        ...customTheme.components.Button.variants.solid(props),
                        color: 'lightGray'
                    }
                }
            }
        },
        Heading: {
            baseStyle: {
                fontFamily: 'montserrat'
            }
        }
    },
    styles: {
        global: (props) => ({
            '.narrowScroll': {
                '::-webkit-scrollbar': {
                    width: '9px',
                },
                '::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent'
                },
                '::-webkit-scrollbar-thumb': {
                    backgroundColor: 'lightGray',
                    rounded: 'full',
                    transition: 'background-color .2s',
                    border: '2px solid transparent',
                    backgroundClip: 'content-box',
                }
            },
            '.ping': {
                animation: 'ping 1s cubic-bezier(0, 0, 0.2, 1) infinite',
                '@keyframes ping': {
                    '75%, 100%': {
                        transform: 'scale(2)',
                        opacity: 0
                    }
                }
            }
        })
    }
} as ThemeOverride)


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <>
        <ColorModeScript initialColorMode={customTheme.config.initialColorMode} />
        <ChakraProvider theme={customTheme}>
            <App />
        </ChakraProvider>
    </>
);