import { IconButton, useColorMode } from "@chakra-ui/react"
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const ThemeChanger = () => {
    const {colorMode, toggleColorMode} = useColorMode();

    return (
        <IconButton
            aria-label="Change theme"
            icon={<FontAwesomeIcon icon={(colorMode === 'light') ? faMoon : faSun} />}
            variant='icon'
            onClick={toggleColorMode}
        />
    )
}

export default ThemeChanger