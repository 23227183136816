import { HStack, SimpleGrid } from "@chakra-ui/react";
import Layout from "./components/Layout";
import CalendarPanel from "./components/sections/CalendarPanel";
import HeaderSection from "./components/sections/Header";
import OrdersPanel from "./components/sections/OrdersPanel";
import SummarySection from "./components/sections/Summary";
import TasksPanel from "./components/sections/TasksPanel";
import Shell from "./components/Shell";

function App() {
    return (
        <Layout>
            <HeaderSection />
            <SummarySection />
            <OrdersPanel />
            <Shell as={SimpleGrid} columns={[1, 1, 1, 1, 1, 2]}>
                <TasksPanel />
                <CalendarPanel />
            </Shell>

        </Layout>
    );
}

export default App;
