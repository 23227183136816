import { Button, Center, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, IconButton, useDisclosure, VStack } from "@chakra-ui/react";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MyMSG, { MyMSGProps } from "../MyMSG";
import Shell from "../Shell";

const alerts: MyMSGProps[] = [
    {
        sender: 'Richard Meighan',
        msg: 'Please, visit me at my office',
        date: '2:38 PM, Today',
    },
    {
        sender: 'Michelle Perry',
        msg: 'I\'ve finished the task you\'ve asked me to do',
        date: '12:28 PM, Today',
    },
    {
        sender: 'Kimberly Buckingham',
        msg: 'Hey, how is your day going?',
        date: '10:07 AM, Today',
    },
    {
        sender: 'Lisa Brown',
        msg: 'I\'m not so sure I\'ve understood you completely..',
        date: '8:45 AM, Today',
    },
    {
        sender: 'Joan Smith',
        msg: 'Did you know it\'s illegal to feed pigeons on the sidewalks and streets in San Francisco?',
        date: '8:32 AM, Today',
    },
]

const MSGButton = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <IconButton
                aria-label="Open messages"
                icon={<FontAwesomeIcon icon={faMessage} />}
                variant='icon'
                onClick={onOpen}
            />

            <Drawer
                isOpen={isOpen}
                placement='right'
                onClose={onClose}
                size='md'
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerHeader>Recent messages</DrawerHeader>

                    <DrawerBody>
                        <Shell as={VStack}>
                            {alerts.map((a, id) => {
                                return (
                                    <MyMSG key={id} {...a} />
                                )
                            })}
                        </Shell>
                        <Center>
                            <Button>Load older</Button>
                        </Center>
                    </DrawerBody>
                </DrawerContent>

            </Drawer>
        </>
    )
}

export default MSGButton;