import { Badge, HStack, IconButton, Menu, MenuButton, MenuItem, MenuList, Text, ThemingProps, VStack } from "@chakra-ui/react";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Shell from "../Shell";

export const TaskTypes = ['new', 'in progress', 'finished', 'cancelled'] as const;

export type TaskCardProps = {
    type: typeof TaskTypes[number]
    title: string,
    hashtags: string[]
}

export const TaskColors: {
    [_ in typeof TaskTypes[number]]: ThemingProps['colorScheme']
} = {
    new: 'purple',
    "in progress": 'yellow',
    finished: 'green',
    cancelled: 'red'
}
const TaskCard = ({ type, title, hashtags }: TaskCardProps) => {
    const colorScheme = TaskColors[type];

    return (
        <Shell as={HStack} bgColor='card' borderWidth={1} w='full' mb='auto' justifyContent='space-between'>
            <VStack justifyContent='space-between' w='full' alignItems='start' gap={2}>
                <Badge variant='subtle' colorScheme={colorScheme}>{type}</Badge>

                <Text>{title}</Text>

                <HStack>
                    {hashtags.map((hashtag, i) => (
                        <Badge variant={'subtle'} key={i}>#{hashtag}</Badge>
                    ))}
                </HStack>
            </VStack>
            <Menu>
                <MenuButton as={IconButton} aria-label="Settings" icon={<FontAwesomeIcon icon={faEllipsisVertical} />} />
                <MenuList>
                    <MenuItem>Edit</MenuItem>
                    <MenuItem>Change status</MenuItem>
                    <MenuItem>Remove</MenuItem>
                </MenuList>
            </Menu>
        </Shell>
    )
}

export default TaskCard;